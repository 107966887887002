/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import React, {
  FC, useContext, useRef,
} from 'react';
import { navigate } from 'gatsby';
// import { useLocation } from '@reach/router';
import Card from '@/newcar/ui/detail/molecule/Card';
import SimulationManagerStoreContext from '@/newcar/ui/common/context/SimulationManagerStoreContext';
import SimulationResultManagerStoreContext from '@/newcar/ui/common/context/SimulationResultManagerStoreContext';
import InterruptionStoreContext from '@/newcar/ui/common/context/InterruptionStoreContext';
import { Observer } from 'mobx-react-lite';
import { ModeType } from '@/newcar/util/const/dialogMode';

import * as styles from '@/newcar/ui/detail/organism/PlanSP.module.styl';
import CARMO_CONST from '@/common/util/const';
import NEWCAR_CONST from '@/newcar/util/const';
import NEWCAR_DEFINE from '@/newcar/util/define';

type PlanSPProps = {
  thumbnailUrl: string;
  onClickCustomizePlan: (
    dialogMode: ModeType,
    planID: NEWCAR_CONST.SIMULATION_PLAN.ID,
    initialStep?: number,
  )=>void;
}

const PlanSP:FC<PlanSPProps> = ({
  thumbnailUrl,
  onClickCustomizePlan,
}) => {
  // const location = useLocation();

  const simulationManagerStore = useContext(SimulationManagerStoreContext);
  const simulationResultManagerStore = useContext(SimulationResultManagerStoreContext);
  const interruptionStore = useContext(InterruptionStoreContext);

  const ctaButton = useRef<HTMLDivElement>(null);

  // const [viewCtaButton, setViewCtaButton] = useState(false);

  // function getIntersectionObserver() {
  //   try {
  //     const observer = new IntersectionObserver(
  //       ([entry]) => {
  //         setViewCtaButton(entry.isIntersecting);
  //       },
  //       {
  //         threshold: 0.5,
  //       },
  //     );
  //     return observer;
  //   } catch (e) {
  // iOS12.1搭載のSafariなどIntersectionObserverに非対応ブラウザの場合はnullを返却
  //     return null;
  //   }
  // }

  // CTAボタンが表示領域に入っているかどうかを監視
  // useEffect(() => {
  //   const observer = getIntersectionObserver();

  //   if (observer && ctaButton.current) {
  //     observer.observe(ctaButton.current);
  //   }

  //   return () => {
  //     if (observer && ctaButton.current) {
  //       observer.unobserve(ctaButton.current);
  //     }
  //   };
  // }, []);

  const showDialog = (dialogMode: ModeType, step: number):void => {
    onClickCustomizePlan(dialogMode, NEWCAR_CONST.SIMULATION_PLAN.ID.ORIGINAL, step);
  };

  // const showLeaseTerm = () => {
  //   onClickCustomizePlan('leaseTerm', NEWCAR_CONST.SIMULATION_PLAN.ID.ORIGINAL, 1);
  // };

  const nowSelectGrade = (): { name: string, sub: string } | undefined => {
    if (!simulationManagerStore.isOldSimulation || simulationManagerStore.nowSelectGrade) {
      // 最新のマスタから情報を表示する
      return simulationManagerStore.nowSelectGrade;
    }
    return simulationResultManagerStore.selectedGrade;
  };

  const nowSelectBodyColor = (): {
    bodyColorId?: string,
    bodyColorName?: string,
    colorCode1?: string,
    colorCode2?: string,
    isSelected?: boolean
  } | undefined => {
    if (!simulationManagerStore.isOldSimulation) {
      // 最新のマスタから情報を表示する
      return simulationManagerStore.nowSelectBodyColor;
    }
    // 保存済みシミュレーションの情報を表示する
    return simulationResultManagerStore.selectedBodyColor;
  };

  const nowSelectOption = (): string | undefined => {
    if (!simulationManagerStore.isOldSimulation) {
      // 最新のマスタから情報を表示する
      return simulationManagerStore.nowSelectOption;
    }
    // 保存済みシミュレーションの情報を表示する
    return simulationResultManagerStore.selectedOption;
  };

  const nowSelectMaintenance = (): string | undefined => {
    if (!simulationManagerStore.isOldSimulation) {
      // 最新のマスタから情報を表示する
      return simulationManagerStore.nowSelectMaintenance;
    }
    // 保存済みシミュレーションの情報を表示する
    return simulationResultManagerStore.selectedMaintenance;
  };

  const hasGrade = () => {
    if (!simulationManagerStore.isOldSimulation) {
      const list = simulationManagerStore.nowSelectGrade;
      return (list !== undefined);
    }
    // 保存済みシミュレーションのため常にtrue
    return true;
  };

  const hasBodyColor = () => {
    if (!simulationManagerStore.isOldSimulation) {
      const list = simulationManagerStore.nowSelectBodyColor;
      return (list !== undefined);
    }
    const list = simulationResultManagerStore.selectedBodyColor;
    return (list !== undefined && list.isSelected !== undefined && list.isSelected);
  };

  const hasOption = () => {
    if (!simulationManagerStore.isOldSimulation) {
      const list = simulationManagerStore.nowSelectOption;
      return (list !== undefined);
    }
    const list = simulationResultManagerStore.selectedOption;
    return (list !== undefined);
  };

  const hasMaintenance = (): boolean => {
    if (!simulationManagerStore.isOldSimulation) {
      const list = simulationManagerStore.nowSelectMaintenance;
      return (list !== undefined);
    }
    const list = simulationResultManagerStore.selectedMaintenance;
    return (list !== undefined);
  };

  const isAvailableMaintenance = (): boolean => {
    const result = simulationManagerStore.maintenancePlanList.length > 0;
    return result;
  };

  const nowSelectNomalPrice = (): string => {
    let price: number | undefined;
    if (!simulationManagerStore.isOldSimulation) {
      price = simulationManagerStore.nowSelectBasePrice;
    } else {
      price = simulationResultManagerStore.selectedNomalPrice;
    }
    if (price) {
      return CARMO_CONST.COMMA_FORMAT(price);
    }
    return '---,---';
  };

  const nowSelectDiscountPrice = (): string => {
    let price: number | undefined;
    if (!simulationManagerStore.isOldSimulation) {
      price = simulationManagerStore.nowSelectPrice;
    } else {
      price = simulationResultManagerStore.selectedNomalPrice;
    }
    if (price) {
      return CARMO_CONST.COMMA_FORMAT(price);
    }
    return '---,---';
  };

  const nowSelectTerm = () => {
    if (!simulationManagerStore.isOldSimulation) {
      return NEWCAR_CONST.TERM.NAME[simulationManagerStore.nowSelectTerm];
    }
    return NEWCAR_CONST.TERM.NAME[simulationResultManagerStore.selectedTerm];
  };

  // const doClear = () => {
  //   simulationManagerStore.clearSelection();
  //   simulationResultManagerStore.clearSelection();
  //   // URLからパラメータを削除
  //   if (window) {
  //     window.history.replaceState(null, '', location.pathname);
  //   }
  // };

  // シミュレーション結果を保存
  // const onClickSave = () => {
  //   simulationResultManagerStore.addSimulation({
  //     customerSelection: simulationManagerStore.nowCustomerSelection,
  //     carDigest: simulationManagerStore.nowCarDigest,
  //     grade: simulationManagerStore.nowGrade,
  //     bodyColor: simulationManagerStore.nowSimulationBodyColor,
  //     totalPrice: simulationManagerStore.nowSelectPrice,
  //     optionstring: simulationManagerStore.nowSelectOption,
  //     option: simulationManagerStore.nowSimulationOpstions,
  //   });
  //   // パラメータを変更
  //   if (window && !/s1=/.test(location.search)) {
  //     const paramstring = simulationManagerStore.urlParams;
  //     if (paramstring) {
  //       if (location.pathname.slice(-1) === '/') {
  //         window.history.replaceState(null, '', `${location.pathname}?${paramstring}&s1=1`);
  //       } else {
  //         window.history.replaceState(null, '', `${location.pathname}/?${paramstring}&s1=1`);
  //       }
  //     }
  //   }
  // };

  const goToEntryForm = () => {
    // 申し込みへ進んだ内容を記録
    interruptionStore.addItem(simulationManagerStore);

    // シミュレーションの履歴を保存
    if (simulationManagerStore.nowCar) {
      simulationManagerStore.changeUserSelect(simulationManagerStore.nowCar);
    }

    if (!simulationManagerStore.isOldSimulation) {
      if (!hasGrade()) {
        simulationManagerStore.nowCarToUndefined();
        simulationManagerStore.saveEntryBreakdown(NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND_SP);
      } else {
        simulationManagerStore.saveEntryBreakdown(NEWCAR_CONST.SIMULATION_PLAN.ID.ORIGINAL);
      }
      navigate(NEWCAR_DEFINE.PATH.FORM_CONTACT_INFO);
    } else if (simulationResultManagerStore.nowSimulation) {
      simulationResultManagerStore.saveEntryBreakdownFromSimulationResult(
        simulationResultManagerStore.nowSimulation,
      );
      navigate(NEWCAR_DEFINE.PATH.FORM_CONTACT_INFO);
    }
  };

  return (
    <Observer>
      {() => (
        <Card className={`${styles.cardPadding}`}>
          <h2 className="title is-3 has-text-centered">
            <span className="is-size-5">お好きなプランを作成して</span>
            <br />
            月額料金をみてみる
          </h2>
          {/* <div className="is-relative">
            <button
              className={`${hasGrade() || 'is-hidden'} button is-text is-size-5 ${styles.clearButton}`}
              type="button"
              onClick={doClear}
              data-gtm="gtm-newcar-detail-simulatorSP-clear"
            >
              条件をクリア
            </button>
          </div> */}

          <div>
            {/* ご利用期間 */}
            <dl className={`columns is-mobile is-padding-1 ${styles.itemWrap}`}>
              <dt className="column is-narrow has-text-weight-bold">
                <span />
                <p className={styles.itemTitle}>ご利用期間</p>
              </dt>
              <dd className="column has-text-grey is-margin-right-3">
                {!hasGrade() ? (
                  <div
                    className={`is-size-4 input-warning ${styles.selectBoxBig} ${styles.notSelected}`}
                    onClick={():void => showDialog('sp', 1)}
                    data-gtm="gtm-newcar-detail-simulatorSP-start"
                  >
                    ご利用期間を選択してください
                    <span className={styles.iconArrowRightGrey} />
                  </div>
                ) : (
                  <div
                    className={`input-inputted has-text-weight-semibold ${styles.textSimulationSelected} ${styles.selectBox} ${styles.selected}`}
                    onClick={():void => showDialog('sp', 1)}
                    data-gtm="gtm-newcar-detail-simulatorSP-term"
                  >
                    {nowSelectTerm()}
                    <span className={styles.simulationChangeButton}>変更</span>
                  </div>
                )}
              </dd>
            </dl>

            {/* グレード */}
            <dl className={`columns is-mobile is-padding-1 ${styles.itemWrap} ${!hasGrade() && styles.itemBgWhiteter}`}>
              <dt className="column is-narrow has-text-weight-bold">
                <span />
                <p className={styles.itemTitle}>グレード</p>
              </dt>
              <dd className="column has-text-grey is-margin-right-3">
                {!hasGrade() ? (
                  <p className="is-padding-left-5">未選択</p>
                ) : (
                  <div
                    className={`input-inputted ${styles.selectBox} ${styles.selected}`}
                    onClick={():void => showDialog('sp', 2)}
                    data-gtm="gtm-newcar-detail-simulatorSP-grade"
                  >
                    <strong className={`is-block has-text-weight-semibold ${styles.textSimulationSelected}`}>
                      {nowSelectGrade()?.name}
                    </strong>
                    <small>{nowSelectGrade()?.sub}</small>
                    <span className={styles.simulationChangeButton}>変更</span>
                  </div>
                )}
              </dd>
            </dl>

            {/* ボディカラー */}
            <dl className={`columns is-mobile is-padding-1 ${styles.itemWrap} ${!hasGrade() && styles.itemBgWhiteter}`}>
              <dt className="column is-narrow has-text-weight-bold">
                <span />
                <p className={styles.itemTitle}>ボディカラー</p>
              </dt>
              <dd className="column has-text-grey is-margin-right-3">
                {!hasGrade() ? (
                  <p className="is-padding-left-5">未選択</p>
                ) : (
                  <div
                    className={`input-inputted ${styles.selectBox} ${hasBodyColor() ? styles.selected : styles.notSelected}`}
                    onClick={():void => showDialog('sp', 3)}
                    data-gtm="gtm-newcar-detail-simulatorSP-bodyColor"
                  >
                    {!hasBodyColor() ? (
                      <>
                        <p>ボディーカラーを選択</p>
                        <span className={styles.iconArrowRightGrey} />
                      </>
                    ) : (
                      <div className="columns is-mobile is-vcentered">
                        <div className="column is-narrow is-flex is-padding-right-2">
                          <svg viewBox="0 0 30 30" width="30" height="30" className={!nowSelectBodyColor()?.colorCode2 ? '' : 'is-hidden'}>
                            <circle r="15" cx="15" cy="15" fill={nowSelectBodyColor()?.colorCode1} stroke="#aaaaaa" strokeWidth="0.5px" />
                          </svg>
                          <div className={`${styles.simulator_two_colorchip} ${nowSelectBodyColor()?.colorCode2 ? '' : 'is-hidden'}`}>
                            <svg viewBox="0 0 30 30" width="30" height="30">
                              <circle r="15" cx="15" cy="15" fill={nowSelectBodyColor()?.colorCode1} stroke="#aaaaaa" strokeWidth="0.5px" />
                            </svg>
                            <svg viewBox="0 0 30 9" width="30 " height="9" className={styles.simulator_above_colorchip}>
                              <clipPath id="simulator_bodycolor_clip">
                                <rect x="0" y="0" width="30" height="9" />
                              </clipPath>
                              <circle r="15" cx="15" cy="15" fill={nowSelectBodyColor()?.colorCode2} stroke="#aaaaaa" strokeWidth="0.5px" clipPath="url(#simulator_bodycolor_clip)" />
                            </svg>
                          </div>
                        </div>
                        <span className={`column has-text-weight-semibold ${styles.textSimulationSelected}`}>{nowSelectBodyColor()?.bodyColorName}</span>
                        <span className={styles.simulationChangeButton}>変更</span>
                      </div>
                    )}
                  </div>
                )}
                <div className={`is-size-6 is-padding-top-1 has-text-danger ${!simulationManagerStore.isResetBodyColor && 'is-hidden'}`}>グレードの変更によりリセットされました</div>
              </dd>
            </dl>

            {/* オプション */}
            <dl className={`columns is-mobile is-padding-1 ${styles.itemWrap} ${!hasGrade() && styles.itemBgWhiteter}`}>
              <dt className="column is-narrow has-text-weight-bold">
                <span />
                <p className={styles.itemTitle}>オプション</p>
              </dt>
              <dd className="column has-text-grey is-margin-right-3">
                {!hasGrade() ? (
                  <p className="is-padding-left-5">未選択</p>
                ) : (
                  <div
                    className={`input-inputted ${styles.selectBox} ${hasOption() && nowSelectOption() ? styles.selected : styles.notSelected}`}
                    onClick={():void => showDialog('sp', 4)}
                    data-gtm="gtm-newcar-detail-simulatorSP-option"
                  >
                    {!hasOption() || !nowSelectOption() ? (
                      <p>オプションを選択</p>
                    ) : (
                      <p className={`has-text-weight-semibold ${styles.textSimulationSelected}`}>{nowSelectOption()}</p>
                    )}
                    <span className={styles.simulationChangeButton}>変更</span>
                  </div>
                )}
                <div className={`is-size-6 is-padding-top-1 has-text-danger ${!simulationManagerStore.isResetOption && 'is-hidden'}`}>グレードの変更によりリセットされました</div>
              </dd>
            </dl>

            {/* メンテナンスプラン */}
            <dl className={`columns is-mobile is-padding-1 ${styles.itemWrap} ${!hasGrade() && styles.itemBgWhiteter}`}>
              <dt className="column is-narrow has-text-weight-bold">
                <span />
                <p className={styles.itemTitle}>メンテナンス</p>
              </dt>
              <dd className="column has-text-grey is-margin-right-3">
                {!hasGrade() && (
                  <p className="is-padding-left-5">未選択</p>
                )}
                {hasGrade() && !isAvailableMaintenance() && (
                  <div className={`is-size-4 input-warning ${styles.selectBox} ${styles.notSelected}`}>
                    この車種ではご利用いただけません
                  </div>
                )}
                {hasGrade() && isAvailableMaintenance() && (
                  <div
                    className={`input-inputted ${styles.selectBox} ${hasMaintenance() ? styles.selected : styles.notSelected}`}
                    onClick={():void => showDialog('sp', 5)}
                    data-gtm="gtm-newcar-detail-simulatorSP-MaintePlan"
                  >
                    {!hasMaintenance() ? (
                      <>
                        <p>メンテナンスを選択</p>
                        <span className={styles.iconArrowRightGrey} />
                      </>
                    ) : (
                      <div className="columns is-mobile is-vcentered">
                        {nowSelectMaintenance() !== 'メンテプランなし' && (
                          <div className="column is-narrow is-flex is-padding-right-2">
                            <svg viewBox="0 0 30 30" width="30" height="30">
                              <circle r="15" cx="15" cy="15" fill="#2c5488" stroke="#aaaaaa" strokeWidth="0.5px" className={nowSelectMaintenance() !== 'プラチナメンテプラン' ? 'is-hidden' : ''} />
                              <circle r="15" cx="15" cy="15" fill="#dfaf2e" stroke="#aaaaaa" strokeWidth="0.5px" className={nowSelectMaintenance() !== 'ゴールドメンテプラン' ? 'is-hidden' : ''} />
                              <circle r="15" cx="15" cy="15" fill="#b2b2b2" stroke="#aaaaaa" strokeWidth="0.5px" className={nowSelectMaintenance() !== 'シルバーメンテプラン' ? 'is-hidden' : ''} />
                            </svg>
                          </div>
                        )}
                        <span className={`column has-text-weight-semibold ${styles.textSimulationSelected}`}>{nowSelectMaintenance()}</span>
                        <span className={styles.simulationChangeButton}>変更</span>
                      </div>
                    )}
                  </div>
                )}
                <div className={`is-size-6 is-padding-top-1 has-text-danger ${!simulationManagerStore.isResetOption && 'is-hidden'}`}>グレードの変更によりリセットされました</div>
                <div className={`is-size-6 is-padding-top-1 has-text-danger ${!simulationManagerStore.isResetMaintenance && 'is-hidden'}`}>リース期間の変更により選択解除されました</div>
              </dd>
            </dl>
          </div>

          {/* 合計金額 */}
          <div className={`is-padding-3 is-padding-top-5 is-padding-bottom-5 ${styles.priceWrap}`}>
            <div className="columns is-mobile">
              <div className="column">
                <h3 className="title is-5 has-text-black-ter is-margin-bottom-3">お選びのプラン合計金額</h3>
              </div>
            </div>
            <div className="columns is-mobile">
              <div className="column">
                <div>
                  <dl className="columns is-mobile is-vcentered is-size-6">
                    <dt className={`column is-narrow ${styles.width4_5rem}`}>通常価格</dt>
                    <dd className="column is-narrow">
                      <div className="columns is-mobile is-vcentered">
                        <div className={`column is-size-7 is-narrow has-text-right is-margin-right-3 ${styles.textUpRight} ${styles.width2rem}`}>
                          月額
                        </div>
                        <div className="column is-narrow">
                          <strong className={`is-size-3 ${hasGrade() ? 'is-strikethrou' : ''}`}>{hasGrade() ? nowSelectNomalPrice() : '---,--- '}</strong>
                          円(税込)
                        </div>
                      </div>
                    </dd>
                  </dl>
                  <div className={`${styles.priceTrico} ${styles.isTablet}`} />
                  <dl className="columns is-mobile is-vcentered is-size-6">
                    <dt className={`column is-narrow has-text-weight-bold is-line-height-small ${styles.priceColorBlue} ${styles.width4_5rem}`}>
                      ネット割
                      <br />
                      適用後価格
                    </dt>
                    <dd className="column is-narrow">
                      <div className="columns is-mobile is-vcentered">
                        <div className={`column is-size-7 is-narrow has-text-right is-margin-right-3 has-text-weight-semibold ${styles.textUpRight} ${styles.width2rem}`}>
                          月額
                        </div>
                        <div className="column is-narrow">
                          <strong className={`${styles.priceColorBlue} is-size-2`}>
                            {nowSelectDiscountPrice()}
                            {' '}
                          </strong>
                          <span className="has-text-weight-semibold">円(税込)</span>
                        </div>
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
              <div className="is-flex has_vertical_centered">
                <img
                  src={thumbnailUrl}
                  width="80"
                  height="45"
                  alt="車画像"
                  decoding="async"
                />
              </div>
            </div>

            <div className="has-text-right is-margin-top-3 is-flex is-hidden-desktop is-hidden-mobile is-hidden-touch">
              <span className={`${styles.balloonCampaign}`}>
                <span className={`is-relative ${styles.balloonCampaignItem}`}>無料！5分でかんたんお申し込み</span>
              </span>
            </div>
            <div className={`${styles.naviPlanBalloon}`}>
              最大
              <span className={`${styles.naviPlanText}`}>
                3万円
              </span>
              分当たる！キャンペーン対象
            </div>

            <div className="columns is-mobile has-text-centered">
              {/* CTAボタン */}
              <div className="column" ref={ctaButton}>
                <button
                  type="button"
                  className={`
                    button is-large is-size-4 is-full-width has-text-white 
                    ${hasGrade() ? `${styles.blueButton}` : `${styles.blueButton} ${styles.isDisabled}`}
                  `}
                  onClick={() => goToEntryForm()}
                >
                  <strong>まずはお試しで審査に申込む</strong>
                  <span className={styles.iconArrowRightBig} />
                </button>
                <p className="is-size-7 is-inline-desktop is-block-touch is-margin-top-1">
                  ※審査申込みは契約ではありません
                </p>
              </div>
            </div>
          </div>
        </Card>
      )}
    </Observer>
  );
};

export default PlanSP;
