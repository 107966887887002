import React, { FC, useState, useContext } from 'react';
import { MAKER_NAME, MAKER_ID } from '@/newcar/util/const/maker';
import { Observer } from 'mobx-react-lite';
import Color from '@/newcar/ui/detail/molecule/Color';
import { VehicleTypes } from '@/common/core/model/catalog/vehicleTypes';
import CarDigest from '@/common/core/model/carDigest';
import { simulationManagerStore } from '@/newcar/core/store/simulationManagerStore';
import SimulationManagerStoreContext from '@/newcar/ui/common/context/SimulationManagerStoreContext';
import InterruptionStoreContext from '@/newcar/ui/common/context/InterruptionStoreContext';
import { scrollToId } from '@/newcar/ui/contract/function';
// import loading from '@/newcar/ui/lineup/img/loading.svg';

import * as styles from '@/newcar/ui/detail/organism/Information.module.styl';
import NEWCAR_CONST from '@/newcar/util/const';
import NEWCAR_DEFINE from '@/newcar/util/define';
import { navigate } from 'gatsby';

type PickVehicleType = Pick<VehicleTypes, 'photos' | 'summary'>;
type PickDigest = Pick<CarDigest, 'name' | 'makerId' | 'bodyColors' | 'wltc' | 'jc08' | 'minVehiclePrice' | 'taxedMinPrice11' | 'has4SeatOrLess' | 'has5Seat' | 'has6Seat' | 'has7Seat' | 'has8SeatOrMore' | 'thumbnailUrl'>;
type IsNowSale = {isNowSale: boolean}
type InformationProps = PickVehicleType & PickDigest & IsNowSale;

const Information:FC<InformationProps> = ({
  photos,
  summary,
  name,
  makerId,
  bodyColors,
  jc08,
  wltc,
  minVehiclePrice,
  taxedMinPrice11,
  has4SeatOrLess,
  has5Seat,
  has6Seat,
  has7Seat,
  has8SeatOrMore,
  isNowSale,
  thumbnailUrl,
}) => {
  const seatArr = [has4SeatOrLess, has5Seat, has6Seat, has7Seat, has8SeatOrMore];
  const minSeat = seatArr.indexOf(true);
  const maxSeat = seatArr.lastIndexOf(true);
  const hasSeats = minSeat === maxSeat ? `${minSeat + 4}人${minSeat === 0 ? '以下' : ''}` : `${minSeat + 4}〜${maxSeat + 4}人`;
  const [isOpen, setIsOpen] = useState(false);
  const gasMillage = NEWCAR_CONST.MILEAGE.WHICH_USE(jc08, wltc);
  const src = (photos && photos[1].imageUrl) || thumbnailUrl || '';

  const interruptionStore = useContext(InterruptionStoreContext);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const goToEntryForm = () => {
    // 申し込みへ進んだ内容を記録
    interruptionStore.addItem(simulationManagerStore, NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND_SP);

    // シミュレーションの履歴を保存
    if (simulationManagerStore.nowCar) {
      simulationManagerStore.changeUserSelect(simulationManagerStore.nowCar);
    }

    simulationManagerStore.nowCarToUndefined();
    simulationManagerStore.saveEntryBreakdown(NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND_SP);
    navigate(NEWCAR_DEFINE.PATH.FORM_CONTACT_INFO);
  };

  const simulationManagerStoreShaped = useContext(SimulationManagerStoreContext);
  const afterPrice = () => simulationManagerStoreShaped.nowRecommendSPSelection?.price?.toLocaleString() || '';

  return (
    <div className={styles.borderLikeCard}>
      <div className={`columns is-mobile ${styles.titleWrap}`}>
        <div className="column">
          <h1 className={`has-text-weight-bold ${styles.carName}`}>
            {MAKER_NAME(makerId as unknown as MAKER_ID)}
            &nbsp;
            <span className="is-hidden-desktop"> </span>
            {name}
            <span className={`${styles.carNameAddition} has-text-weight-normal is-block`}>のカーリース情報</span>
          </h1>
        </div>
      </div>

      <div className={`columns ${styles.mainSpacing}`}>
        <div className="column is-7-tablet is-two-fifths-desktop">
          <div className="has-text-centered-mobile">
            <img
              className={`${styles.mainImg}`}
              src={src}
              width="512"
              height="288"
              alt="車画像"
              decoding="async"
            />
          </div>
        </div>

        <div className={`column ${styles.mainInfo}`}>
          {isNowSale ? (
            <>
              <h2 className="is-hidden-touch is-size-3 has-text-grey">{summary}</h2>

              {/* 価格表示PC */}
              <div className={`columns is-mobile is-hidden-touch ${styles.boxLeftLineGrey}`}>
                <dl className="column columns is-narrow is-vcentered">
                  <dt className="column is-narrow is-size-6 has-text-weight-semibold is-line-height-small">
                    最安料金&nbsp;&nbsp;月額定額
                  </dt>
                  <dd className="column is-narrow is-mobile is-vcentered is-margin-left-3">
                    <strong className="is-size-3 has-text-weight-semibold">
                      {taxedMinPrice11.toLocaleString()}
                      <span className="is-size-7 has-text-grey">円〜×132回(11年)</span>
                    </strong>
                  </dd>
                </dl>
                <ul className="column columns">
                  <li className="column is-narrow">
                    <p className={`is-size-6 has-text-grey has-text-weight-semibold ${styles.roundedBox}`}>
                      頭金・ボーナス払い0円
                    </p>
                  </li>
                  <li className="column is-narrow">
                    <p className={`is-size-6 has-text-grey has-text-weight-semibold ${styles.roundedBox}`}>
                      法的費用・手数料コミ
                    </p>
                  </li>
                </ul>
              </div>
              {/* //_価格表示PC */}

              {/* 価格表示SP */}
              <div className={`${styles.monthlyFeeContainer}`}>
                <div className="is-size-6 has-text-weight-semibold">
                  最安料金&nbsp;
                  <br />
                  月額定額
                </div>
                <strong className="is-size-1">{taxedMinPrice11.toLocaleString()}</strong>
                <div className="is-padding-1 is-line-height-small">
                  <span className="is-size-6 has-text-black-ter">円〜</span>
                  <br />
                  <span className="is-size-7 has-text-grey">×132回(11年)</span>
                </div>
              </div>
              {/* //_価格表示SP */}

              {/* PC表示 */}
              <ul className="is-hidden-touch columns is-mobile">
                <li className={`column is-narrow-desktop ${styles.inner}`}>
                  <dl>
                    <dt className="is-size-6 has-text-weight-semibold">
                      燃費
                      <span className="has-text-weight-normal">{gasMillage.name}</span>
                    </dt>
                    <dd className="is-size-4 has-text-grey">
                      {gasMillage.value}
                      km/L
                    </dd>
                  </dl>
                </li>
                <li className={`column is-3-tablet-only is-narrow-desktop ${styles.inner}`}>
                  <dl>
                    <dt className="is-size-6 has-text-weight-semibold">定員</dt>
                    <dd className="is-size-4 has-text-grey">{hasSeats}</dd>
                  </dl>
                </li>
                <li className={`column is-narrow-desktop ${styles.inner}`}>
                  <dl>
                    <dt className="is-size-6 has-text-weight-semibold">車両本体価格</dt>
                    <dd className={`has-text-grey ${styles.minVehiclePrice}`}>
                      {minVehiclePrice.toLocaleString()}
                      円〜
                    </dd>
                  </dl>
                </li>
                <li className={`column ${styles.inner} ${styles.maxWidthColor}`}>
                  <dl className={styles.isMarginMinus}>
                    <dt className="is-size-6 has-text-weight-semibold">カラー</dt>
                    <dd>
                      <Color bodyColors={bodyColors} desktop />
                    </dd>
                  </dl>
                </li>
              </ul>

              {/* SP表示 */}
              <ul className={`is-hidden-desktop columns is-mobile ${styles.specListContainer}`}>
                <li className={`column ${styles.innerLight}`}>
                  <dl>
                    <dt className={`${styles.specHeading} has-text-weight-bold`}>
                      {`燃費${gasMillage.name}`}
                    </dt>
                    <dd className="is-size-4">
                      {`${gasMillage.value}km/L`}
                    </dd>
                  </dl>
                </li>
                <li className={`column ${styles.innerLight}`}>
                  <dl>
                    <dt className={`${styles.specHeading} has-text-weight-bold`}>定員</dt>
                    <dd className="is-size-4">{hasSeats}</dd>
                  </dl>
                </li>
                <li className={`column ${styles.innerLight}`}>
                  <dl>
                    <dt className={`${styles.specHeading} has-text-weight-bold`}>車両本体価格</dt>
                    <dd className={`${styles.minVehiclePrice}`}>
                      {`${minVehiclePrice.toLocaleString()}円〜`}
                    </dd>
                  </dl>
                </li>
                <li className={`column ${styles.innerLight}`}>
                  <dl className="is-hidden-desktop is-mobile is-vcentered is-centered is-margin-top-1">
                    <dt className={`${styles.specHeading} has-text-weight-bold`}>カラーバリエーション</dt>
                    <dd className="is-size-4">
                      <Color bodyColors={bodyColors} desktop={false} />
                    </dd>
                  </dl>
                </li>
              </ul>

              <div className="is-hidden-desktop has-text-centered is-margin-bottom-4">
                {/*
                  <div className="has-text-center is-margin-top-5 balloon-campaign-parent">
                    <span className={`is-size-6 is-relative ${styles.balloonCampaign}`}>
                    無料！5分でかんたんお申し込み</span>
                  </div>
                 */}
                <div className={`${styles.naviPlanBalloon}`}>
                  おすすめ!
                  <span className={`${styles.naviPlanText}`}>
                    ナビ付きプラン
                    <span className={`${styles.naviPlanTextMin}`}>
                      (月額
                      <Observer>
                        {() => (
                          <>
                            {afterPrice()}
                          </>
                        )}
                      </Observer>
                      円)
                    </span>
                  </span>
                  で
                </div>
                <button
                  type="button"
                  className={`button is-large is-size-4 ${styles.blueButton} ${styles.isShaped}`}
                  onClick={() => goToEntryForm()}
                >
                  <strong className="is-size-4">まずはお試しで審査に申込む</strong>
                  <span className={styles.iconArrowRightBig} />
                </button>
                <p className="is-size-6">※審査申込みは契約ではありません</p>
              </div>
              <div
                className="columns is-mobile is-multiline is-centered is-vcentered is-padding-bottom-4 is-hidden-desktop"
                role="button"
                tabIndex={0}
                onClick={() => toggleAccordion()}
              >
                <p
                  className={`column is-narrow is-size-6 is-padding-right-2 ${styles.isUnderline}`}
                >
                  ナビ付きプランの詳細
                </p>
                <span className={`${styles.plusBtn} ${isOpen ? styles.active : ''}`} />
              </div>
              {isOpen && (
              <div className={`columns is-hidden-desktop is-multiline is-centered is-vcentered is-size-6 is-padding-5 is-margin-bottom-5 ${styles.isBorderGreyLighter}`}>
                <div className="column is-padding-bottom-3">
                  <p className="has-text-weight-semibold">グレード</p>
                  <p className="has-text-grey">{simulationManagerStore.nowRecommendSPSelection.orderName}</p>
                </div>
                <div className="column is-padding-bottom-3">
                  <p className="has-text-weight-semibold">オプション装備</p>
                  <p className="has-text-grey">{simulationManagerStore.nowRecommendSPSelection.option}</p>
                </div>
                <div className="column is-padding-bottom-3">
                  <p className="has-text-weight-semibold">賃貸期間</p>
                  <p className="has-text-grey">{`${(simulationManagerStore.nowRecommendSPSelection.term || 11) * 12}ヶ月 ${simulationManagerStore.nowRecommendSPSelection.term || 11}年`}</p>
                </div>
                <div className="column is-padding-bottom-3">
                  <p className="has-text-weight-semibold">月額料金</p>
                  <p className="has-text-grey">{`${(simulationManagerStore.nowRecommendSPSelection.price?.toLocaleString())}円/月（税込）`}</p>
                </div>
              </div>
              )}

              <button
                className={`is-hidden-desktop ${styles.anchorLinkButton}`}
                type="button"
                onClick={() => scrollToId('simulation')}
              >
                自分でプランを作成する
              </button>
            </>
          ) : (
            <p className={`${styles.salesStopMessage}`}>
              現在、この車は販売停止中のため、
              <br />
              入荷・再販までお時間を
              <br />
              頂戴しております。
              <br />
              お待ちいただく間、他の車も
              <br />
              あわせてご検討ください。
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Information;
