import React, { FC } from 'react';
import CARMO_CONST from '@/common/util/const';
import CarDigest from '@/common/core/model/carDigest';
import SEOBase from '@/newcar/ui/common/organism/SEO';

type DetailSEOProps = Pick<CarDigest, 'name' | 'makerId' | 'bodyType' | 'taxedMinPrice11'>;

const SEO: FC<DetailSEOProps> = ({
  name, makerId, taxedMinPrice11,
}) => {
  const carName = `${CARMO_CONST.MAKER.NAME(makerId)} ${name}`;
  const manYen = taxedMinPrice11 / 10000;
  const priceText = manYen && manYen > 0
    ? `${manYen >= 1 ? `${Math.floor(manYen)}` : `${manYen.toFixed(1)}`}`
    : '';

  // title
  const titlePriceText = priceText ? ` 月額${priceText}万円台から` : '';
  const title = `${carName}の新車カーリース・サブスク｜定額カルモくん${titlePriceText}`;

  // description
  const DESCRIPTION_PREFIX = '【ネット割最大49,500円】';
  const DESCRIPTION_SUFFIX = '頭金・ボーナス払い無しで毎月定額、税金コミコミで安心の新車カーリース（車のサブスク）ならおトクにマイカー 定額カルモくん。メンテナンスも月額定額、契約満了後に車がもらえるプランもご用意。';
  const descriptionPriceText = priceText ? `${carName}の新車が月々${priceText}万円台から｡` : '';
  const description = `${DESCRIPTION_PREFIX}${descriptionPriceText}${DESCRIPTION_SUFFIX}`;

  return (
    <SEOBase
      title={title}
      description={description}
      ogTitle={title}
      ogDescription={description}
      ogUrl={`${process.env.GATSBY_SITE_URL}`}
      twitterTitle={title}
      twitterDescription={description}
    />
  );
};

export default SEO;
