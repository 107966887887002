/* eslint-disable max-len */
import React, {
  FC, useContext, useState, useEffect,
} from 'react';
import { navigate } from 'gatsby';
import NEWCAR_CONST from '@/newcar/util/const';
import NEWCAR_DEFINE from '@/newcar/util/define';
import SimulationManagerStoreContext from '@/newcar/ui/common/context/SimulationManagerStoreContext';
import searchCarStoreContext from '@/newcar/ui/common/context/searchCarStoreContext';
import InterruptionStoreContext from '@/newcar/ui/common/context/InterruptionStoreContext';
import ColorSquare from '@/newcar/ui/detail/molecule/ColorSquare';
import * as styles from '@/newcar/ui/detail/organism/modal/ModalSP.module.styl';

interface ModalResultProps {
  isShow: boolean;
  thumbnailUrl: string;
}

const ModalResult: FC<ModalResultProps> = ({ isShow, thumbnailUrl }) => {
  const simulationManagerStore = useContext(SimulationManagerStoreContext);
  const searchCarStore = useContext(searchCarStoreContext);
  const interruptionStore = useContext(InterruptionStoreContext);

  const carDigest = searchCarStore.lastViewCarDigest;
  const makerName = carDigest ? carDigest.makerName : '';
  const carName = carDigest ? carDigest.name : '';

  // グレード
  const grade = simulationManagerStore.nowSelectGrade;
  const gradeName = grade ? grade.name : '';
  // const gradeSub = grade ? grade.sub : '';

  // 金額
  const nomalPrice = simulationManagerStore.nowSelectBasePrice ? simulationManagerStore.nowSelectBasePrice.toLocaleString() : '';
  const discountPrice = simulationManagerStore.nowSelectPrice ? simulationManagerStore.nowSelectPrice.toLocaleString() : '';

  // ボディカラー
  const bodyColor = simulationManagerStore.nowSelectBodyColor;
  const bodyColorName = bodyColor ? bodyColor.bodyColorName : '';
  const colorCode1 = bodyColor ? bodyColor.colorCode1 : '';
  const colorCode2 = bodyColor ? bodyColor.colorCode2 : '';

  // オプション
  const hasCarNavi = simulationManagerStore.nowSelectCarNavi !== '100001';
  const carNaviPrice = simulationManagerStore.carNaviList[0] ? simulationManagerStore.carNaviList[0].price?.toLocaleString() : '';
  const hasBackCamera = simulationManagerStore.nowSelectBackCamera !== '100002';
  const backCameraPrice = simulationManagerStore.backCameraList[0] ? simulationManagerStore.backCameraList[0].price?.toLocaleString() : '';
  const hasEtc = simulationManagerStore.nowSelectEtc !== '100003';
  const etcPrice = simulationManagerStore.etcList[0] ? simulationManagerStore.etcList[0].price?.toLocaleString() : '';
  const hasFloorMat = simulationManagerStore.nowSelectFloorMat !== '100004';
  const floorMatPrice = simulationManagerStore.floorMatList[0] ? simulationManagerStore.floorMatList[0].price?.toLocaleString() : '';
  const hasWantNo = simulationManagerStore.nowSelectWantNo !== '100005';
  const wantNoPrice = simulationManagerStore.wantNoList[0] ? simulationManagerStore.wantNoList[0].price?.toLocaleString() : '';
  const hasDoorVisor = simulationManagerStore.nowSelectDoorVisor !== '100006';
  const doorVisorPrice = simulationManagerStore.doorVisorList[0] ? simulationManagerStore.doorVisorList[0].price?.toLocaleString() : '';
  const hasWantCar = simulationManagerStore.nowSelectWantCar !== 'no';
  const wantCarPrice = simulationManagerStore.wantCarPrice ? simulationManagerStore.wantCarPrice.toLocaleString() : '';
  const hasOption = hasCarNavi || hasBackCamera || hasEtc || hasFloorMat || hasWantNo || hasDoorVisor || hasWantCar;

  // メンテプラン
  const maintenanceName = NEWCAR_CONST.MAINTENANCE.PLAN_SHORT_NAME(simulationManagerStore.nowPickedMaintenancePlan) || 'なし';
  const maintenanceColor = () => {
    switch (simulationManagerStore.nowPickedMaintenancePlan) {
      case NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM:
        return '#2c5488';
      case NEWCAR_CONST.MAINTENANCE.PLAN.GOLD:
        return '#dfaf2e';
      case NEWCAR_CONST.MAINTENANCE.PLAN.SILVER:
        return '#b2b2b2';
      default:
        return '';
    }
  };
  const maintenancePrice = () => {
    const list = simulationManagerStore.maintenancePlanList;
    const targetId = simulationManagerStore.nowPickedMaintenancePlan;
    const maintenancePlan = list.find((item) => (item.id === targetId));
    return maintenancePlan ? maintenancePlan.price.toLocaleString() : '';
  };

  const [style, setStyle] = useState({});

  useEffect(() => {
    if (isShow) {
      setStyle({});
    } else {
      // コンテンツの高さが一番高いところで揃ってしまうので非表示の時は高さを0にする
      setTimeout(() => setStyle({ height: '0px' }), 400);
    }
  }, [isShow]);

  const goToEntryForm = () => {
    // 申し込みへ進んだ内容を記録
    interruptionStore.addItem(simulationManagerStore);

    if (simulationManagerStore.nowSelectGrade) {
      simulationManagerStore.saveEntryBreakdown(NEWCAR_CONST.SIMULATION_PLAN.ID.ORIGINAL);
    } else {
      simulationManagerStore.nowCarToUndefined();
      simulationManagerStore.saveEntryBreakdown(NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND_SP);
    }
    navigate(NEWCAR_DEFINE.PATH.FORM_CONTACT_INFO);
  };

  return (
    <div className={`${styles.content}`} style={style} data-type="simulation-result">
      <div className="has-background-white is-padding-5 is-margin-bottom-3">
        <div className={styles.resultCarContainer}>
          <div className={styles.resultCarThumbnail}>
            <img src={thumbnailUrl} alt="車画像" height="64" />
          </div>
          <div className={styles.resultCarName}>
            {/* <h3 className="has-text-weight-bold">月額料金</h3> */}
            <p>
              {makerName}
              <br />
              <span className="has-text-weight-bold">
                {carName}
              </span>
              {/* <small className="is-padding-left-5">{gradeSub}</small> */}
            </p>
          </div>
        </div>
        <div className="columns is-mobile is-padding-top-5 is-margin-bottom-3">
          <div className={`${styles.resultPrice}`}>
            <div className={`is-flex ${styles.resultPriceGap}`}>
              <p className={`${styles.item} ${styles.adjust} is-size-6 is-padding-right-3`}>通常価格</p>
              <div className={`${styles.resultPriceContentRight} ${styles.isNormal}`}>
                <span className="is-size-5">月額</span>
                <s>
                  {nomalPrice}
                  <small className="is-size-6">円(税込)</small>
                </s>
              </div>
            </div>
            <div className={`${styles.priceTrico}`} />
            <div className={`is-flex ${styles.resultPriceGap}`}>
              <strong className={`${styles.item} ${styles.priceColorBlue} ${styles.priceContentLeft} is-size-6 is-padding-right-3`}>
                ネット割
                <br />
                適用後価格
              </strong>
              <div className={styles.resultPriceContentRight}>
                <span className={styles.resultPriceMonthlyLabel}>月額</span>
                <span>
                  <strong className={`${styles.discountPrice} ${styles.priceColorBlue}`}>{discountPrice}</strong>
                  <small className="is-size-6 has-text-black-ter">円(税込)</small>
                </span>
              </div>
            </div>
          </div>
        </div>

        <p className={`${styles.annotation} ${styles.asterisk} is-margin-bottom-5`}>写真はイメージです。シミュレーションで選択したカラーやグレードと異なっていることがあります。</p>

        {/* CTA */}
        <div className={`${styles.naviPlanBalloon}`}>
          最大
          <span className={`${styles.naviPlanText}`}>
            3万円
          </span>
          分当たる！キャンペーン対象
        </div>
        <div>
          <button
            type="button"
            className={`
              ${styles.resultCta} ${styles.isBlue}
              button is-large is-size-4 is-full-width has-text-white
            `}
            data-gtm="gtm-newcar-simulator-modalResult-FVnextBtn"
            onClick={goToEntryForm}
          >
            <strong>まずはお試しで審査に申込む</strong>
            <span className="icon-arrow-right-big is-margin-right-2" />
          </button>
          <p className={`${styles.annotation} has-text-centered is-margin-top-3`}>
            ※審査申込みは契約ではありません
            <br />
            ※審査後でもグレード変更・オプション追加できます
          </p>
        </div>
      </div>

      <div className={`${styles.resultDetail} is-padding-5`}>
        <h3 className="has-text-weight-bold is-margin-bottom-5">シミュレーション内容</h3>
        <dl>
          <div className="is-padding-left-3 is-padding-bottom-3">
            <dt className={`has-text-weight-semibold ${styles.resultCategoryTitle}`}>
              ご利用期間
            </dt>
            <dd className="has-text-weight-bold">
              {simulationManagerStore.nowSelectTerm}
              年
            </dd>
          </div>
          <div className="is-padding-left-3 is-margin-top-4 is-padding-bottom-3">
            <dt className={`has-text-weight-semibold ${styles.resultCategoryTitle}`}>グレード</dt>
            <dd className="has-text-weight-bold">{gradeName}</dd>
          </div>
          <div className="is-padding-left-3 is-margin-top-4 is-padding-bottom-3">
            <dt className={`has-text-weight-semibold ${styles.resultCategoryTitle}`}>カラー</dt>
            <dd className="has-text-weight-bold is-flex has_vertical_centered">
              {bodyColorName ? (
                <>
                  <ColorSquare width={15} height={15} colorCode1={colorCode1} colorCode2={colorCode2} />
                  <span className="is-padding-left-2">{bodyColorName}</span>
                </>
              ) : (
                <>未選択（ご商談時に選べます）</>
              )}
            </dd>
          </div>
          <div className={`${styles.option} is-padding-left-3 is-margin-top-4 is-padding-bottom-3`}>
            <dt className={`has-text-weight-semibold ${styles.resultCategoryTitle}`}>オプション装備</dt>
            {hasCarNavi && (
              <div className="columns is-mobile">
                <dd className="column has-text-weight-bold">カーナビ</dd>
                <dd className="column is-narrow">{`+${carNaviPrice}円`}</dd>
              </div>
            )}
            {hasBackCamera && (
              <div className="columns is-mobile">
                <dd className="column has-text-weight-bold">バックカメラ</dd>
                <dd className="column is-narrow">{`+${backCameraPrice}円`}</dd>
              </div>
            )}
            {hasEtc && (
              <div className="columns is-mobile">
                <dd className="column has-text-weight-bold">ETC</dd>
                <dd className="column is-narrow">
                  {`+${etcPrice}円`}
                  {' '}
                </dd>
              </div>
            )}
            {hasFloorMat && (
              <div className="columns is-mobile">
                <dd className="column has-text-weight-bold">フロアマット</dd>
                <dd className="column is-narrow">{`+${floorMatPrice}円`}</dd>
              </div>
            )}
            {hasWantNo && (
              <div className="columns is-mobile">
                <dd className="column has-text-weight-bold">希望ナンバー</dd>
                <dd className="column is-narrow">{`+${wantNoPrice}円`}</dd>
              </div>
            )}
            {hasDoorVisor && (
              <div className="columns is-mobile">
                <dd className="column has-text-weight-bold">ドアバイザー</dd>
                <dd className="column is-narrow">{`+${doorVisorPrice}円`}</dd>
              </div>
            )}
            {/* もらえるオプションはリース年数変えると外れることがあるため条件を厳しく */}
            {(hasWantCar && wantCarPrice) && (
              <div className="columns is-mobile">
                <dd className="column has-text-weight-bold">もらえるオプション</dd>
                <dd className="column is-narrow">{`+${wantCarPrice}円`}</dd>
              </div>
            )}
            {!hasOption && (
              <div className="columns is-mobile has-text-weight-bold">なし</div>
            )}
          </div>
          <div className="is-padding-left-3 is-margin-top-4">
            <dt className={`has-text-weight-semibold ${styles.resultCategoryTitle}`}>メンテナンスプラン</dt>
            <div className="columns is-mobile">
              <dd className="column is-flex has_vertical_centered">
                {maintenanceColor() && (
                  <svg viewBox="0 0 15 15" width="15" height="15">
                    <rect fill={maintenanceColor()} x="0" y="0" width="15" height="15" stroke="#aaaaaa" strokeWidth="0.5px" />
                  </svg>
                )}
                <span className="is-padding-left-2 has-text-weight-bold">{maintenanceName}</span>
              </dd>
              {maintenancePrice() && (
                <dd className="column is-narrow">{`+${maintenancePrice()}円`}</dd>
              )}
            </div>
          </div>
        </dl>
      </div>

      <div className="is-padding-5 is-margin-bottom-6">
        <p className="is-size-5">【月額料金に含まれるもの】</p>
        <p className="is-size-5">
          車両本体料、自賠責保険料、自動車税、重量税、印紙代、環境性能割、新車登録手数料、仲介手数料、お客様サポート料
          <br />
          ※月額料金に含まれない車検点検費用は、メンテナンスプランご加入で定額支払いにできます。
        </p>
      </div>
    </div>
  );
};

export default ModalResult;
